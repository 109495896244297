<template>
  <div v-loading="loading" class="add-action-button" v-if="!refresh">
    <div class="text-center mb-2">
      <el-radio-group v-model="field.is_global_variable" >
        <el-radio-button :label="false">Custom Field</el-radio-button>
        <el-radio-button :label="true">Global Variable</el-radio-button>
      </el-radio-group>
    </div>
    <el-form label-position="right" v-if="field.is_global_variable">
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <div class="form-group">
          
            <el-form-item label="Global Variable">
              <el-select v-model="field.global_variable_id" @change="setGlobalVariable">
                <el-option
                  v-for="(globalVariable,index) of allGlobalVariables"
                  :key="index"
                  :label="globalVariable.label"
                  filterable
                  :value="globalVariable._id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <el-form label-position="right" v-if="!field.is_global_variable">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item  label="Add Button Label"><p style="color: red;font-weight: bold;">*</p>
              <el-input
                type="text"
                v-model="field.label"
                placeholder="Enter label"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>

        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Type">
              <el-select
                v-model="field.action_button_type"
                placeholder="Select Button Type"
                @change="getRepeatableTemplates"
                style="width: 100%"
              >
                <el-option
                  v-for="(type, index) of buttonTypes"
                  :key="index"
                  :label="type.label"
                  :value="type.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <div class="float-right">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-plus"
            @click="addNewAction"
          >
            Add
          </el-button>
        </div>
      </el-row>
      <el-row>
        <el-scrollbar wrap-style="max-height: 200px;">
          <el-row
            :gutter="20"
            v-for="(action, i) in field.actions"
            :key="i"
            style="margin-bottom: 3px; padding-bottom: 10px"
          >
            <el-col :span="5">
              <el-select
                placeholder="Select type"
                v-model="action.action_button_action_type"
                style="width: 100%"
                @change="handleActionTypeChange(action)"
              >
                <el-option
                  v-for="(type, index) of actionTypes"
                  :key="index"
                  :label="type.label"
                  :value="type.value"
                  :disabled="checkDisabled(type.value, i)"
                  >{{ type.label }}</el-option
                >
              </el-select>
            </el-col>
            <template v-if="action.action_button_action_type == 'NAVIGATION'">
              <el-col :span="6">
                <el-select
                  placeholder="Select type"
                  v-model="action.action_button_action_navigation_type"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(type, index) of navigationTypes"
                    :key="index"
                    :label="type.label"
                    :value="type.value"
                    >{{ type.label }}</el-option
                  >
                </el-select>
              </el-col>
              <el-col
                :span="6"
                v-if="
                  action.action_button_action_navigation_type ==
                  'OPEN_FORM_BUILDER'
                "
              >
                <el-select
                  v-model="action.action_button_formbuilder_id"
                  placeholder="Select form builder"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(type, index) of formbuilderList"
                    :key="type._id + index"
                    :label="type.name"
                    :value="type._id"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col
                :span="6"
                v-if="
                  action.action_button_action_navigation_type ==
                  'OPEN_DOCUMENT_TEMPLATE_BUILDER'
                "
              >
                <el-select
                  v-model="action.action_button_document_template_id"
                  placeholder="Select document template"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(type, index) of documentTemplatesList"
                    :key="type.value + index"
                    :label="type.title"
                    :value="type.value"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="6">
                <el-select
                  placeholder="Select type"
                  v-model="action.action_button_target_location"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(type, index) of locationTypes"
                    :key="index"
                    :label="type.label"
                    :value="type.value"
                    >{{ type.label }}</el-option
                  >
                </el-select>
              </el-col>
              <el-col
                :span="6"
                v-if="action.action_button_action_navigation_type == 'OPEN_URL'"
              >
                <el-input
                  v-model="action.action_button_target_link"
                  placeholder="Enter url"
                >
                </el-input>
                <span
                  style="color: red"
                  v-if="
                    action.action_button_target_link &&
                    !isValidURL(action.action_button_target_link)
                  "
                >
                  Invalid URL
                </span>
              </el-col>
            </template>
            <template
              v-else-if="action.action_button_action_type == 'TEMPLATE_ACTION'"
            >
              <el-col :span="6">
                <div class="mt-1">
                  Note: You can apply form rules on this action button
                </div>
              </el-col>
            </template>
            <template
              v-else-if="
                action.action_button_action_type == 'CREATE_DUPLICATE'
              "
              >
              <el-col :span="6">
                <el-select
                  v-model="action.selected_fields" 
                  placeholder="select Fields"
                  multiple
                  collapse-tags>
                  <el-option
                    v-for="item in fieldsData"
                    :disabled="checkIsFieldRequired(item)"
                    :key="item.key"
                    :label="item.label"
                    :value="item.template_id+'#'+item.key">
                  </el-option>
                </el-select>
              </el-col>
            </template>
            <template
              v-else-if="
                action.action_button_action_type == 'ESTABLISH_RELATIONSHIP'
              "
            >
              <el-col :span="10">
                <el-select
                  placeholder="Entity one"
                  v-model="action.relational_entity_one"
                  style="width: 100%"
                  filterable
                >
                  <el-option
                    v-for="entity in entities"
                    :key="entity._id"
                    :value="entity._id"
                    :label="entity.name"
                  >
                  </el-option>
                </el-select>
                <el-radio-group v-model="action.action_button_relationship_type" v-if="action.relational_entity_one">
                  <el-tooltip placement="top" :content="getTooltipContent('assign_only', action.relational_entity_one)">
                    <el-radio :label="1">Assign only</el-radio>
                  </el-tooltip>
                  <el-tooltip placement="bottom" :content="getTooltipContent('add_only', action.relational_entity_one)">
                    <el-radio :label="2">Add only</el-radio>
                  </el-tooltip>
                  <el-tooltip placement="top" :content="getTooltipContent('assign_add', action.relational_entity_one)">
                    <el-radio :label="3">Assign & Add</el-radio>
                  </el-tooltip>
                  <el-tooltip placement="bottom" :content="getTooltipContent('view_data', action.relational_entity_one)">
                    <el-radio :label="4">View data</el-radio>
                  </el-tooltip>
                </el-radio-group>
              </el-col>
              <el-col :span="1">
                <div style="font-size: 30px">
                  <i class="el-icon-right"></i>
                </div>
              </el-col>
              <el-col :span="3">
                <div style="font-size: 20px; margin-top: 10px">SELF ENTITY</div>
              </el-col>
              <!-- <el-col :span="1">
                <div style="font-size: 30px">
                  <i class="el-icon-right"></i>
                </div>
              </el-col>
              <el-col :span="3">
                <el-select
                  placeholder="After "
                  v-model="action.call_back"
                  style="width: 100%"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="entity in callBacks"
                    :key="entity.value"
                    :value="entity.value"
                    :label="entity.label"
                  >
                  </el-option>
                </el-select>
              </el-col> -->
            </template>
            <el-col :span="1">
              <el-link
                class="mt-1"
                type="danger"
                :underline="false"
                @click="deleteAction(i)"
                icon="el-icon-delete"
              >
              </el-link>
            </el-col>
          </el-row>
        </el-scrollbar>
      </el-row>
      <el-row style="border-top: 1px dotted #e6e6e6">
        <b>Style settings</b>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="form-group">
            <el-form-item label="Font family">
              <el-select
                v-model="field.styles.font.name"
                placeholder="Select font"
                style="width: 100%"
              >
                <el-option
                  v-for="(type, index) of fontTypes"
                  :key="index"
                  :label="type.label"
                  :value="type.value"
                  :style="`font-family: ${type.value}`"
                  >{{ type.label }}</el-option
                >
              </el-select>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="6" v-if="field.action_button_type == 'BUTTON'">
          <div class="form-group">
            <el-form-item label="Button type">
              <el-radio-group v-model="field.styles.botton_type">
                <el-radio-button label="Rectangular"></el-radio-button>
                <el-radio-button label="Rounded"></el-radio-button>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="form-group d-flex">
            <el-form-item label="Text">
              <el-color-picker
                v-model="field.styles.label_color"
              ></el-color-picker>
            </el-form-item>
            <el-form-item label="Background">
              <el-color-picker
                v-model="field.styles.input_background"
              ></el-color-picker>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "templates-formComponents-ActionButton",
  components: {},
  props: ["field", "fieldsData", "selfTemplateId"],
  computed: {
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("documents", ["getAllTemplates"]),
    ...mapGetters("formBuilders", ["getAllFormBuildersData"]),
    ...mapGetters("entities", ["getAllEntities"]),
    repeatingTemplates() {
      return this.getAllCompanyTemplatesData
        ? this.getAllCompanyTemplatesData.data || []
        : [];
    },
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    allGlobalVariables() {
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    }
  },
  data() {
    return {
      fontTypes: [
        {
          value: "Calibri",
          label: "Calibri",
        },
        {
          value: "Helvetica",
          label: "Helvetica",
        },
        {
          value: "Georgia",
          label: "Georgia",
        },
      ],
      locationTypes: [
        {
          value: "SELF",
          label: "Same Page",
        },
        {
          value: "NEW_TAB",
          label: "New Tab",
        },
        {
          value: "NEW_WINDOW",
          label: "New Window",
        },
      ],
      buttonTypes: [
        {
          label: "Button",
          value: "BUTTON",
        },
        {
          label: "HyperLink",
          value: "HYPERLINK",
        },
      ],
      actionTypes: [
        {
          value: "NAVIGATION",
          label: "Navigation",
        },
        {
          value: "TEMPLATE_ACTION",
          label: "Update template",
        },
        {
          value: "ESTABLISH_RELATIONSHIP",
          label: "Relationships",
        },
        {
          value : "CREATE_DUPLICATE",
          label : "Duplicate Data"
        }
      ],
      navigationTypes: [
        {
          value: "OPEN_URL",
          label: "Open URL",
        },
        {
          value: "OPEN_FORM_BUILDER",
          label: "Open Form builder",
        },
        {
          value: "OPEN_DOCUMENT_TEMPLATE_BUILDER",
          label: "Open document template",
        },
      ],
      entities: [],
      selectedTemplateFields: [],
      childEntities: [],
      relationShipConfiguration: [],
      loading: false,
      reloadRelationship: false,
      refresh: true,
      formbuilderList: [],
      documentTemplatesList: [],
      callBacks: [
        {
          value: "REFRESH_PARENT",
          label: "Refresh parent",
        },
        {
          value: "CLOSE_PARENT",
          label: "Close parent",
        },
        {
          value: "UPDATE_PARENT",
          label: "Update parent",
        },
      ],
    };
  },
  async mounted() {
    this.fetchGlobalVaribales();
    this.loading = true;
    if (!this.field.styles || !this.field.styles.botton_type) {
      this.$set(this.field, "styles", {
        botton_type: "Rectangular",
        font: {
          name: "Helvetica",
          size: 16,
          style: 0,
          color: "#409eff",
        },
        label_color: "#ffffff",
        input_background: "#f754a2",
      });
    }
    if (!this.field?.actions) {
      this.$set(this.field, "actions", []);
    }
    setTimeout(() => {
      this.refresh = false;
    }, 100);
    await Promise.all([
      this.$store.dispatch("formBuilders/fetchAllFormBuilders", {
        get_all: true,
      }),
      this.$store.dispatch("documents/fetchAllTemplates", { get_all: true }),
      this.$store.dispatch("entities/fetchEntities", {
        get_all: true,
      }),
    ]);
    if (this.getAllEntities?.data) {
      this.entities = this.getAllEntities.data;
    }
    if (this.getAllTemplates?.data) {
      this.documentTemplatesList = (this.getAllTemplates.data || []).map(
        (e) => {
          return {
            value: e._id + "#" + e.configurable_document_id,
            title: e.title,
          };
        }
      );
    }
    if (this.getAllFormBuildersData?.data) {
      this.formbuilderList = this.getAllFormBuildersData.data;
    }
    this.loading = false;

    console.log("this.documentTemplatesList", this.documentTemplatesList);
  },
  methods: {
    async fetchGlobalVaribales() {
     
     let params = {
       get_all: true,
       input_type: this.field.input_type
     };

     await this.$store.dispatch(
       "globalVariables/fetchGlobalVariables",
       params
     );
   },
   setGlobalVariable() {
     let globalVariable = this.allGlobalVariables.find(
       x => x._id == this.field.global_variable_id
     );
     this.field.label = globalVariable.label;
     this.field.description = globalVariable.description;
     this.field.options = globalVariable.options;
     this.field.actions=globalVariable.actions;
   },
    getTooltipContent(type, entityId) {
      let label = "";
      let entity = this.entities.find(e => e._id == entityId);
      let entityName = entity?.name || 'Selected entity';
      switch (type) {
        case "assign_only":
          label = "Can only assign existed " + entityName + " to self entity";
          break;
        case "add_only":
          label = "Can only add new " + entityName + " and that "+entityName +" will be assign to self entity";
          break;
        case "assign_add":
          label = "Can add new "+ entityName +" or assign existed " + entityName + " to self entity";
          break;
        case "view_data":
          label = "Can view " + entityName + " related to self entity";
          break;
      }
      return label;
    },
    checkDisabled(type, i) {
      if (
        ["NAVIGATION", "TEMPLATE_ACTION", "ESTABLISH_RELATIONSHIP"].indexOf(
          type
        ) != -1
      ) {
        let existed = (this.field.actions || []).findIndex(
          (e) => e.action_button_action_type == type
        );
        if (existed > -1 && existed != i) {
          return true;
        }
        return false;
      }
      return false;
    },
    deleteAction(i) {
      this.field.actions.splice(i, 1);
    },
    addNewAction() {
      this.field.actions.push({
        ...JSON.parse(
          JSON.stringify({
            action_button_action_type: "",
            action_button_action_navigation_type: "",
            action_button_target_location: "",
            selected_fields : []
          })
        ),
      });
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    async fetchRepeatableTemplates() {
      await this.$store.dispatch("companyTemplates/getAllCompanyTemplates", {
        get_all: true,
        is_repeatable: true,
      });
    },
    async addSelfTemplateFields() {
      this.entities = [];
      if (this.fieldsData && this.fieldsData.length) {
        this.fieldsData.forEach((element) => {
          if (element.input_type == "GLOBAL_VARIABLE") {
            element["is_template_variable"] = true;
            this.entities.push(element);
          }
        });
      }
    },

    checkAlreadyExistedInOthers(entity) {
      let relationships =
        this.field.action_button_repeating_template_relationships;
      let existedRelationship = relationships.find((x) => {
        return x.relationships && x.relationships.length
          ? x.relationships.find((y) => y.key == entity.key)
          : null;
      });

      return existedRelationship ? true : false;
    },
    async setRelationships(reset) {
      if (!reset) {
        return;
      }
      this.field.action_button_repeating_template_relationships =
        this.entities.map((entity) => {
          return {
            entity,
            relationships: [],
          };
        });
      let data = this.fieldsData.filter((field) => {
        if (
          field.input_type == "ENTITY_VARIABLE" &&
          field.relationship_entity_id
        ) {
          return {
            entity: field,
            relationships: [],
          };
        }
      });
      data = data.map(function (element) {
        return {
          entity: element,
          relationships: [],
        };
      });
      this.field.action_button_repeating_template_relationships = [
        ...this.field.action_button_repeating_template_relationships,
        ...data,
      ];
    },
    async addChildemplateFields() {
      if (this.selectedTemplateFields && this.selectedTemplateFields.length) {
        this.childEntities = [];
        this.selectedTemplateFields.forEach((element) => {
          if (element.input_type == "GLOBAL_VARIABLE") {
            element["is_template_variable"] = true;
            this.childEntities.push(element);
          }
        });
      }
    },

    async getRepeatableTemplates() {
      if (this.field.action_button_type == "TEMPLATE")
        await this.fetchRepeatableTemplates();
    },
    onSelectRepeatableTemplate(id, reset = true) {
      this.reloadRelationship = true;
      let template = this.repeatingTemplates.find((x) => {
        return x._id == id;
      });
      if (template && template.sections && template.sections.length) {
        this.selectedTemplateFields = template.sections[0].fields;
        this.addChildemplateFields();
      }

      this.setRelationships(reset);

      this.reloadRelationship = false;
    },
    handleActionTypeChange(action) {
      if(action.action_button_action_type == "CREATE_DUPLICATE" && !action.selected_fields?.length) {
        this.fieldsData.map((e) => {
          action.selected_fields.push(`${e.template_id}#${e.key}`)
        })
      }      
    },
    checkIsFieldRequired(field) {
      if(field.validations.required ||
        field.input_type == "AUTO_INCREMENT_NUMBER" ||
        field.inputType == "AUTO_INCREMENT_NUMBER"
        ) {
        return true;
      }
      return false;
    }
  },
};
</script>

<style lang="scss"></style>
